import AOS from "aos";
import "aos/dist/aos.css";

const MyPlugin = {
  install(app) {
    app.use(
      AOS.init({
        duration: 600,
        easing: "ease-in-out-sine",
        offset: 0,
      })
    );
  },
};
export default MyPlugin;
