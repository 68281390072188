import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//Plugins
import aos from "@/plugins/aos";

const app = Vue.createApp(App);

app.use(router);
app.use(store);
app.use(aos);

app.mount("#app");
