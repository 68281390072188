import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/page/Home.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/page/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/page/Terms.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
